var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('v-navigation-drawer', {
    staticClass: "the_side_nav",
    class: {
      'outage-error': _vm.outageAlertState === _vm.AlertType.ERROR,
      'outage-warning': _vm.outageAlertState === _vm.AlertType.WARNING
    },
    attrs: {
      "value": _vm.value,
      "app": "",
      "aria-orientation": "vertical",
      "disable-resize-watcher": ""
    },
    on: {
      "input": _vm.emitInput
    }
  }, [_c('v-list', {
    staticClass: "py-0"
  }, [_c('v-list-tile', {
    staticClass: "reporting_menu_selection",
    attrs: {
      "active-class": "",
      "exact": "",
      "to": "/reporting"
    },
    on: {
      "click": _vm.closeSideNav,
      "focus": _vm.focusEvent
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    staticClass: "nav_icon",
    attrs: {
      "icon": "fad fa-chart-line",
      "icon-size": "21px",
      "icon-color": _vm.$route.path.startsWith('/reporting') ? 'white' : 'black'
    }
  })], 1), _c('v-list-tile-content', [_c('v-list-tile-title', [_vm._v("Reporting")])], 1)], 1), _vm.isLogisticsAdmin ? _c('v-list-tile', {
    staticClass: "critical_inv_tracking_menu_selection",
    attrs: {
      "to": "/critical_inventory_tracking",
      "active-class": "",
      "exact": ""
    },
    on: {
      "click": _vm.closeSideNav
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    staticClass: "nav_icon",
    attrs: {
      "icon-size": "21px",
      "icon": _vm.$icons['CRITICAL_INVENTORY_TRACKING'],
      "icon-color": _vm.$route.path.startsWith('/critical_inventory_tracking') ? 'white' : 'black'
    }
  })], 1), _c('v-list-tile-content', [_c('v-list-tile-title', [_vm._v("Crit Inv Tracking")])], 1)], 1) : _vm._e(), _vm.isLogisticsAdmin ? _c('v-list-tile', {
    staticClass: "chicklet_menu_selection",
    attrs: {
      "to": "/chicklet",
      "active-class": "",
      "exact": ""
    },
    on: {
      "click": _vm.closeSideNav
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    staticClass: "nav_icon",
    attrs: {
      "icon-size": "21px",
      "icon": _vm.$icons.CHICKLET,
      "icon-color": _vm.$route.path.startsWith('/chicklet') ? 'white' : 'black'
    }
  })], 1), _c('v-list-tile-content', [_c('v-list-tile-title', [_vm._v("Chicklet")])], 1)], 1) : _vm._e(), _c('v-list-tile', {
    staticClass: "nav_item",
    class: {
      nav_item_active: _vm.isRouteDeliveryMatrix
    },
    attrs: {
      "to": `/${_vm.EntityType.DELIVERY_MATRIX_ITEM}/search`,
      "active-class": ""
    },
    on: {
      "click": _vm.closeSideNav
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    staticClass: "nav_icon",
    attrs: {
      "icon-size": "21px",
      "icon": _vm.$icons[_vm.EntityType.DELIVERY_MATRIX_ITEM],
      "icon-color": _vm.$route.path.startsWith(`/${_vm.EntityType.DELIVERY_MATRIX_ITEM}`) ? 'white' : 'black'
    }
  })], 1), _c('v-list-tile-content', [_c('v-list-tile-title', {
    staticClass: "nav_content"
  }, [_vm._v("Delivery Matrix")])], 1)], 1), _c('v-list', _vm._l(_vm.filteredTheSideNavigationMenu, function (item) {
    return _c('v-list-group', {
      key: item.displayName,
      ref: item.displayName.toLowerCase(),
      refInFor: true,
      attrs: {
        "prepend-icon": item.icon,
        "no-action": "",
        "dark": ""
      }
    }, [_c('v-list-tile', {
      attrs: {
        "slot": "activator",
        "active-class": ""
      },
      slot: "activator"
    }, [_c('v-list-tile-content', [_c('v-list-tile-title', [_vm._v(_vm._s(item.displayName))])], 1)], 1), _vm._l(item.children, function (subItem) {
      return _c('v-list-tile', {
        key: subItem.displayName,
        attrs: {
          "to": subItem.to,
          "active-class": "",
          "exact": "",
          "exact-active-class": ""
        },
        on: {
          "click": _vm.closeSideNav
        }
      }, [_c('v-list-tile-content', [_c('v-list-tile-title', [_vm._v(_vm._s(subItem.displayName))])], 1), _c('v-list-tile-action', [_c('c-icon-fa', {
        staticClass: "subgroup_item_icon",
        attrs: {
          "icon": subItem.icon,
          "icon-size": "21px",
          "icon-color": _vm.$route.path.startsWith(`${subItem.to}`) ? 'white' : `${subItem.textColor}`
        }
      })], 1)], 1);
    })], 2);
  }), 1), _c('v-list-tile', {
    staticClass: "nav_item change_log",
    attrs: {
      "tag": "button"
    },
    on: {
      "click": _vm.onClickOfShowChangeLog
    }
  }, [_c('v-list-tile-action', [_c('c-icon-fa', {
    staticClass: "nav_icon",
    attrs: {
      "icon": "fa-thin fa-wand-magic-sparkles"
    }
  })], 1), _c('v-list-tile-content', [_c('v-list-tile-title', [_vm._v("View app updates")])], 1), _c('v-list-tile-content', [_c('v-list-tile-title', {
    staticClass: "text-xs-center monospace_font primary white--text font-weight-bold app_version"
  }, [_vm._v("v" + _vm._s(_vm.appVersion))])], 1)], 1)], 1), _c('hr'), _c('v-list-tile', {
    staticClass: "nav_item cosmic_menu_selection",
    attrs: {
      "href": _vm.ogUrl
    }
  }, [_c('v-list-tile-action', {
    staticClass: "external_link"
  }, [_c('img', {
    attrs: {
      "alt": "Navigate to COSMIC",
      "src": _vm.cosmicLogoSrc,
      "height": "24"
    }
  }), _c('c-icon-fa', {
    staticClass: "ml-3",
    attrs: {
      "icon": _vm.$icons['external_link'],
      "icon-size": "12px",
      "icon-color": "black"
    }
  })], 1)], 1), _c('v-tooltip', {
    attrs: {
      "bottom": "",
      "content-class": "external_app_logo_tooltip"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on
      }) {
        return [_c('v-list-tile', _vm._g({
          staticClass: "cosmic_menu_selection",
          attrs: {
            "href": _vm.cDocsUrl
          }
        }, on), [_c('v-list-tile-action', {
          staticClass: "external_link"
        }, [_c('img', {
          attrs: {
            "alt": `Navigate to COSMIC Docs`,
            "src": _vm.cosmicDocsLogoSrc,
            "height": "28"
          }
        }), _c('c-icon-fa', {
          staticClass: "ml-3",
          attrs: {
            "icon": _vm.$icons['external_link'],
            "icon-size": "12px",
            "icon-color": "black"
          }
        })], 1)], 1)];
      }
    }])
  }, [_vm._v(" Navigate to COSMIC Docs ")]), _vm.showChangeLog ? _c('ModalChangeLog', {
    on: {
      "modal-close": function ($event) {
        _vm.showChangeLog = !_vm.showChangeLog;
      }
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };