/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./App.vue?vue&type=template&id=6cd59d92&scoped=true"
import script from "./App.ts?vue&type=script&lang=ts&external"
export * from "./App.ts?vue&type=script&lang=ts&external"
import style0 from "./App.vue?vue&type=style&index=0&id=6cd59d92&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cd59d92",
  null
  
)

export default component.exports