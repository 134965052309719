<template><section><h1>Change Log</h1>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.4.2...v5.5.0">5.5.0</a> (2024-09-18)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/d94c19eda4b2bb96e1397d715fa91ccc1eaed408">d94c19e</a>)</li>
<li><strong>Schema:</strong> update to v41.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/c46f2b189020cb36aad17e74343851c95e93dae3">c46f2b1</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v40.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/53fac86fc1c8cd178e0817313353b01870f7e109">53fac86</a>)</li>
<li><strong>Schema:</strong> update to v41.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/14dd5bf39a23b34712bb93ab3b2efe6439589477">14dd5bf</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.4.1...v5.4.2">5.4.2</a> (2024-09-11)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/12a4daaaf82be129b898f0c24c86d174e05bcba7">12a4daa</a>)</li>
<li><strong>Schema:</strong> update to v39.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/af241f22f2077e93b5105b22618b8a7ab4eca5ec">af241f2</a>)</li>
<li><strong>Schema:</strong> update to v39.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/3c701dabff64bc6f51f9779d4af533c8e002d5ee">3c701da</a>)</li>
<li><strong>Schema:</strong> update to v39.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b0f7510c196c279b90cf4eea7c93489befdd38cb">b0f7510</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.4.0...v5.4.1">5.4.1</a> (2024-09-05)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/fc611c49e0a0c2e0a205f6c9ceca4de8dff33b69">fc611c4</a>)</li>
<li><strong>UI:</strong> streamlined checkbox controls (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/5a90d5daf2a2aee30911c9a42c5c712f0a091bd4">5a90d5d</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.3.1...v5.4.0">5.4.0</a> (2024-08-30)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/2be37fb2df1e8492f1e0300a44f538bcf3f715f9">2be37fb</a>)</li>
<li><strong>Schema:</strong> update fixes breaks from v39.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/bfa009c596ceab64d3fec5d82b4f3bf71999e739">bfa009c</a>)</li>
<li><strong>UI:</strong> streamlined radio button controls (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ed75e258b1aee74a14e1515e20ce8512f0a61cc1">ed75e25</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v39.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/9c18bbfffa6caab69030b1158fdeac345a9f108b">9c18bbf</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.3.0...v5.3.1">5.3.1</a> (2024-08-27)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/aa53c52e6a77baeff675ce4b827def0cf2375ba7">aa53c52</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.2.1...v5.3.0">5.3.0</a> (2024-08-21)</h1>
<h3>Features</h3>
<ul>
<li><strong>Modal:</strong> implementing new webcomponent backed modal (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f93ff140a043c670c55f98f3741ac1e050c25365">f93ff14</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.2.0...v5.2.1">5.2.1</a> (2024-08-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v38.4.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/296cbf29ce9f03954cae3a420699aad512006994">296cbf2</a>)</li>
<li><strong>Schema:</strong> update to v38.5.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/1118561429d9ce33038b297fe3fc90efb1f7f0b5">1118561</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.1.1...v5.2.0">5.2.0</a> (2024-08-13)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v38.2.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/c897bc9f6de92f14cc39b45ab3989b5b9b2d8035">c897bc9</a>)</li>
<li><strong>Schema:</strong> update to v38.4.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/40b796f62e5c1a0bf7d5997436cebb95c75b6ed0">40b796f</a>)</li>
<li><strong>Schema:</strong> update to v38.4.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/cfa5503ff68da4fd0dd0b5459a7afb796b31fee3">cfa5503</a>)</li>
<li><strong>Schema:</strong> update to v38.4.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f4d526ab217f9b8b614c186c417532a40fc3692e">f4d526a</a>)</li>
<li><strong>Standardization:</strong> implement icons in top nav as well as other wonderful augmentations. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/3b2182a9d625c805e1d47791537afaed2ddf58a2">3b2182a</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Admins view:</strong> update adds more application level details to a new admin tab (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/bee74958b59825d13ca33b1e3d80124f92f2056c">bee7495</a>)</li>
<li><strong>UI:</strong> implemented new button web component (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/1a7196a712907bb0f72bee17d68c5a380bb5620d">1a7196a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.1.0...v5.1.1">5.1.1</a> (2024-07-31)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/e3950d642d862e1e7d2677b69fda8b1094d24136">e3950d6</a>)</li>
<li><strong>Schema:</strong> update to v38.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/7d73aea7903a688c85493cdeb11dbae1a26bacc1">7d73aea</a>)</li>
<li><strong>Schema:</strong> update to v38.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/8d8f4eb8e39d9ea5cad6ea1cd139b9752f304460">8d8f4eb</a>)</li>
<li><strong>Schema:</strong> update to v38.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/2c9113b557d3c8dcfb3d34eb19ad2b47706c2bf2">2c9113b</a>)</li>
<li><strong>Schema:</strong> update to v38.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/1388886e9aed69a2642c073a865985c759c24759">1388886</a>)</li>
<li><strong>Standardization:</strong> implement icons in top nav as well as other wonderful augmentations. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/dec37cf07ee1b09f25797af4a5d347b2bc892798">dec37cf</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v5.0.0...v5.1.0">5.1.0</a> (2024-07-26)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>UI:</strong> restored more familiar hardware icons and updated the edit icon (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/5860978951cb676262e051d258cf8893d3def383">5860978</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Authorization:</strong> overhauled the order of operations for authorization and authentication (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b328c63b74452600ceaf7a412946fc320094f35a">b328c63</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v4.0.2...v5.0.0">5.0.0</a> (2024-07-22)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v38.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/12dc7406f23327e845ca40ea1a7e8142a8783f83">12dc740</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Schema:</strong> date/time updates system wide</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v4.0.1...v4.0.2">4.0.2</a> (2024-07-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Build:</strong> correct build error preventing deployment (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/661e8516591a46a758256b23eab2d80a9fe7e901">661e851</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v4.0.0...v4.0.1">4.0.1</a> (2024-07-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/de959ab3ed3b58658c97e9a98c69ae4852af1282">de959ab</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v3.0.2...v4.0.0">4.0.0</a> (2024-07-11)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Asset pathing:</strong> update to point back to the proxy (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/371f86cc49645fc91dbeaddfb2db9458aec92da7">371f86c</a>)</li>
<li><strong>COSMIC:</strong> Dependency update for deployment. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/1a79b0db843675f80cfced5efd152efc4e8b5bb8">1a79b0d</a>)</li>
<li><strong>Schema:</strong> update to v36.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/a2e366cf6b22284c5cf18891186a1dfcd1bcc186">a2e366c</a>)</li>
<li><strong>Schema:</strong> update to v37.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ec136436e06fb948808e565bb05cc232dfda0615">ec13643</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v36.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/2ca23d9a79cd38cb83298b9fdae7aa38e599760d">2ca23d9</a>)</li>
<li><strong>Tables:</strong> change to table styles (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/6120080cebd7a2c94bc192f8d2af7682ec0f248f">6120080</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Schema:</strong> column renames and date/time related updates</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v3.0.1...v3.0.2">3.0.2</a> (2024-06-25)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Asset path:</strong> Update GCP bucket url. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/82cff903d3453ad0f862c9de01248c16cc9b3c1e">82cff90</a>)</li>
<li><strong>Footer:</strong> updated knowledgebase link (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/85bd6faa279ac837e8e7d9bf67721a2a710c77e3">85bd6fa</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v3.0.0...v3.0.1">3.0.1</a> (2024-06-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v35.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/bf18c6ae607f75f5ed9aba432e947070d83207a9">bf18c6a</a>)</li>
<li><strong>Schema:</strong> update to v35.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/47ddf65482ba04e0dd5783efc90e2d66d2ea3090">47ddf65</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v2.1.4...v3.0.0">3.0.0</a> (2024-06-18)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v35.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/7a19a6043d8a5604f5664a395c76b86e3d194aaa">7a19a60</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Schema:</strong> createdDate/updatedDate rename</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v2.1.3...v2.1.4">2.1.4</a> (2024-06-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v34.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/d708321f97d43c274ac50154a34fc1fe5798ef4f">d708321</a>)</li>
<li><strong>Schema:</strong> update to v34.1.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/86283d69ce3894414cd114c25b2e4a017752af22">86283d6</a>)</li>
<li><strong>Schema:</strong> update to v34.1.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b6791545af21c372231443185a1fc6d954e2f1ee">b679154</a>)</li>
<li><strong>Static assets:</strong> update pathing per environment (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/1a6666a2f8fe98dfcf741d418dda25e62ff511ab">1a6666a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v2.1.2...v2.1.3">2.1.3</a> (2024-06-17)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/087b58decf9d0f01ba8e6dc45476d37156434743">087b58d</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/17af931cdc30d31fe24bafcbb1b4fa40480f00d2">17af931</a>)</li>
<li><strong>Schema:</strong> update to v34.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ae0031e8cf583aeccb5f0f3cd5594368296ef1f7">ae0031e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v2.1.1...v2.1.2">2.1.2</a> (2024-06-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v34.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/c7e4bd217b2de551eda774b37cdd2bb11069665e">c7e4bd2</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v2.1.0...v2.1.1">2.1.1</a> (2024-06-12)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v34.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/09535cf6b2790065383b911f2f4e6ae7bb4a7d36">09535cf</a>)</li>
<li><strong>Schema:</strong> update to v34.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/4bd19f07ab3ea5f662e2e62c6e30a9c395baef3b">4bd19f0</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v2.0.1...v2.1.0">2.1.0</a> (2024-06-11)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v32.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/af7f3df39793a6f9f8c01faa137d61715ff4474f">af7f3df</a>)</li>
<li><strong>Schema:</strong> update to v33.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/328ad049a19e250bd66889c7e5e549dc762281da">328ad04</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Dates:</strong> attempt at fixing datetimes (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/c8fcb8aceb457d4004d59c7348f142a052a5b8b2">c8fcb8a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v2.0.0...v2.0.1">2.0.1</a> (2024-06-10)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Misc:</strong> updates for image pathing (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/c1d01e47df5fe323ff613c7d41df91b74e357d2d">c1d01e4</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.13...v2.0.0">2.0.0</a> (2024-06-10)</h1>
<h3>Features</h3>
<ul>
<li><strong>Static resource mgmt:</strong> change implements current best practices for serving static content (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/8b986f862ef93c40d3f6436c998dba9634fb11e0">8b986f8</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Static resource mgmt:</strong> images could need to be remapped</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.12...v1.0.13">1.0.13</a> (2024-06-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Charts:</strong> adding them (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/6f442d75bee7f50b43b4fed5a32e7a4011a67554">6f442d7</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/d5476fe47207718166d65d5d4abb95d26d868271">d5476fe</a>)</li>
<li><strong>Schema:</strong> update to v31.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/1f795dc3f7156f76fb39e6dde9e90077e3d91bbd">1f795dc</a>)</li>
<li><strong>Schema:</strong> update to v32.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b96450e7318956da8189d393da02bbcbf37d82db">b96450e</a>)</li>
<li><strong>Schema:</strong> update to v32.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/9cec7772c3e3f269c09e8c169699c2f3354c91ed">9cec777</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.11...v1.0.12">1.0.12</a> (2024-06-04)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery matrix search:</strong> updated to default to IN_PROCESS if no status is set (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/6bc10d57ab4d0a8296fa937bd48113d5b889b061">6bc10d5</a>)</li>
<li><strong>Delivery matrix:</strong> updated search for better ui consistency (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/944c3a727a925d2a1c0f5b76bafb1beacb2c8c73">944c3a7</a>)</li>
<li><strong>Schema:</strong> update to v31.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/af875ccec7e33e1e23e58d9337b43bde50b7f61b">af875cc</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.10...v1.0.11">1.0.11</a> (2024-06-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery matrix:</strong> updated how the hardware tracking is saved (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/47b7b4eaa8c122ccf776a0fbe111c77fcc65834a">47b7b4e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.9...v1.0.10">1.0.10</a> (2024-06-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery matrix changelog:</strong> corrected some data related issues (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f7bda51fc0cee2c2baf11e732c2b3b6763aec64b">f7bda51</a>)</li>
<li><strong>Delivery matrix:</strong> corrected issue when completing an item (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/0fd518d72fd3cb7c1d971e029c552c4059073dc9">0fd518d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.8...v1.0.9">1.0.9</a> (2024-06-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Delivery matrix:</strong> update ux (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/839cbe298d029ec65fdc2a8e52635ffc68e279ce">839cbe2</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.7...v1.0.8">1.0.8</a> (2024-06-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>General:</strong> upadates to wording and reenforcement of actions via iconography (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/13b5cb343b52020955142633fffeb71bddbb74d4">13b5cb3</a>)</li>
<li><strong>Logos:</strong> adding them (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/0894acc14f375cd3977224cb587e69feaae4a17b">0894acc</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.6...v1.0.7">1.0.7</a> (2024-06-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v31.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ac1838b561195ac17f0873f9cc31a98eca08ce0b">ac1838b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.5...v1.0.6">1.0.6</a> (2024-05-31)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v31.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/23e343d620c778b6199721b4cb3b7fe09ef599a8">23e343d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.4...v1.0.5">1.0.5</a> (2024-05-31)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Chicklet:</strong> implement new popups for creating critical HL and ROOT MEGA ATs (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b235a9c469801c0fb0dc2892e38b24f51751a25a">b235a9c</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.3...v1.0.4">1.0.4</a> (2024-05-31)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Permissions:</strong> adidng in the all important LOGISTICS_ADMIN permission (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ef345bf72efd334c4b006049daf331020ce80eaf">ef345bf</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.2...v1.0.3">1.0.3</a> (2024-05-31)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Chicklet:</strong> added a create HL modal and implement HL based routes/views (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/822597826bdad9bd17eba6bcb9a19450f4db88fd">8225978</a>)</li>
<li><strong>Chicklet:</strong> fun.tional (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/a48e9b21bd281b74def108cbb233274798edfb52">a48e9b2</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.1...v1.0.2">1.0.2</a> (2024-05-30)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Critical inventory:</strong> initial commit of base feature set (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/5040476a87298612d5c207d2266d3c4ec8375ead">5040476</a>)</li>
<li><strong>Delivery matrix:</strong> initial commit moving over functionality from COSMIC Docs (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/fab4bf40dc3e23f22c6ca8dd0cd58bff8b172ae6">fab4bf4</a>)</li>
<li><strong>Index:</strong> loading up the correct variables file (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/bb0cbfc4fcb3c97d2fd29f5451e14628a0da231d">bb0cbfc</a>)</li>
<li><strong>Schema:</strong> update to v31.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/ecca39f9873bf7184133cae3ca1d3674c9073128">ecca39f</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/compare/v1.0.0...v1.0.1">1.0.1</a> (2024-05-21)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Initial commit:</strong> new app is launched (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/adf546467ba6038a7f48f111ee69f3bb232003f6">adf5464</a>)</li>
<li><strong>Logistics:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/f0f51da2c35c4923997db2f455718c33c890cb89">f0f51da</a>)</li>
</ul>
<h1>1.0.0 (2024-05-21)</h1>
<h3>Bug Fixes</h3>
<ul>
<li>gitlab CI filenam (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/1f2193aa125f0c85990b80055565bcb2fb1a9214">1f2193a</a>)</li>
<li>gitlab CI filename (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/logistics/commit/b305e9e1165a94cd66870a5e78bbe7f2a6754618">b305e9e</a>)</li>
</ul>
</section></template><script>export default {components:{}}</script>