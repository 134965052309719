var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.EVENT,
      "modal-width": "85vw",
      "hide-title-section": ""
    },
    on: {
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": "",
            "mb-2": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs4": "",
            "pr-2": ""
          }
        }, [_c('AppText', {
          attrs: {
            "label": "Name",
            "required": ""
          },
          model: {
            value: _vm.formData.name,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "name", $$v);
            },
            expression: "formData.name"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs3": ""
          }
        }, [_c('AppSelectEnum', {
          attrs: {
            "chips": false,
            "color": _vm.EntityType.EVENT,
            "display": _vm.EventTypeDisplay,
            "enum": _vm.EventType,
            "label": "Type",
            "required": ""
          },
          model: {
            value: _vm.formData.subType,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "subType", $$v);
            },
            expression: "formData.subType"
          }
        })], 1)], 1), _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs12": "",
            "md6": ""
          }
        }, [_c('AppDate', {
          attrs: {
            "label": "Start",
            "required": ""
          },
          model: {
            value: _vm.formData.startDate,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "startDate", $$v);
            },
            expression: "formData.startDate"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs12": "",
            "md6": ""
          }
        }, [_c('AppDate', {
          attrs: {
            "errorMessages": _vm.endDateErrorMessage ? _vm.endDateErrorMessage : [],
            "label": "End",
            "required": ""
          },
          model: {
            value: _vm.formData.endDate,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "endDate", $$v);
            },
            expression: "formData.endDate"
          }
        })], 1)], 1), _c('AppColorPicker', {
          staticClass: "mt-2",
          attrs: {
            "label": "Color"
          },
          model: {
            value: _vm.formData.color,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "color", $$v);
            },
            expression: "formData.color"
          }
        }), _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": "",
            "mt-4": "",
            "mb-2": ""
          }
        }, [_c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.formData.selectedHardwareListAssemblies.length === 0 ? _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppPageDescription', {
          attrs: {
            "color": "warning"
          }
        }, [_vm._v("Select from the Hardware below.")])], 1) : _vm._e()], 1), _c('v-slide-x-transition', {
          attrs: {
            "hide-on-leave": ""
          }
        }, [_vm.formData.selectedHardwareListAssemblies.length ? _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppPageDescription', {
          attrs: {
            "color": "info"
          }
        }, [_vm._v(" Selected "), _c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(_vm._s(_vm.formData.selectedHardwareListAssemblies.length))]), _vm._v(" Hardware items. ")])], 1) : _vm._e()], 1), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppTableSelectable', {
          attrs: {
            "color": _vm.EntityType.INVENTORY,
            "entity-type": _vm.EntityType.INVENTORY,
            "headers": _vm.hardwareTableHeaders,
            "is-loading": _vm.isFetching,
            "items": _vm.computedHardwareTableItems,
            "rows-per-page-items": [-1],
            "disable-initial-sort": "",
            "hide-pagination": "",
            "item-key": "id",
            "dense-rows": "",
            "sticky": ""
          },
          scopedSlots: _vm._u([{
            key: "_type",
            fn: function ({
              item
            }) {
              return [item.isExternal ? _c('div', [_c('v-tooltip', {
                attrs: {
                  "bottom": ""
                },
                scopedSlots: _vm._u([{
                  key: "activator",
                  fn: function ({
                    on
                  }) {
                    return [_c('span', _vm._g({}, on), [_c('IconNotCosmic', {
                      attrs: {
                        "color": "black"
                      }
                    })], 1)];
                  }
                }], null, true)
              }, [_c('span', [_vm._v("Non-" + _vm._s(_vm.currentSystemName) + " Inventory")])])], 1) : _c('div', [_c('i', {
                class: [_vm.$icons[_vm.EntityType.INVENTORY], `${_vm.EntityType.INVENTORY}--text`]
              })])];
            }
          }]),
          model: {
            value: _vm.formData.selectedHardwareListAssemblies,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "selectedHardwareListAssemblies", $$v);
            },
            expression: "formData.selectedHardwareListAssemblies"
          }
        })], 1)], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonCreate', {
          attrs: {
            "disabled": !_vm.isFormValid
          },
          on: {
            "click": _vm.onClickOfCreate
          }
        }, [_vm._v("Create Event")]), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };