var _a;
import { __decorate, __metadata } from "tslib";
import { format } from 'date-fns';
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { AttachmentType, CommentType } from '~/db_types';
import { DeliveryMatrixItemStatus } from '~/db_types/swagger_types';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { AttachmentRepoMixin } from '~/nasa_ui/DAL/attachments';
import { CommentsRepoMixin } from '~/nasa_ui/DAL/comment';
import HttpMixin from '~/nasa_ui/mixins/HttpMixin';
import { Maybe } from '~/nasa_ui/types';
let ModalDeliveryMatrixItemComplete = class ModalDeliveryMatrixItemComplete extends Mixins(AttachmentRepoMixin, BaseCosmic, BaseModal, CommentsRepoMixin, HttpMixin) {
    CommentType = CommentType;
    deliveryMatrixItem = null;
    showAttachmentForm = false;
    attachmentFormData = {
        description: null,
        file: null,
        type: AttachmentType.GENERAL
    };
    formData = {
        comment: null,
        deliveryDate: format(new Date(), 'yyyy-MM-dd'),
        requirementSatisfied: false
    };
    id;
    get deliveryMatrixItemDescription() {
        return this.deliveryMatrixItem?.description || null;
    }
    get dil() {
        return this.deliveryMatrixItem?.deliverableItemsListNumber;
    }
    get deliveryMatrixItemUpdateRequest() {
        if (!this.deliveryMatrixItem || !this.formData.deliveryDate || !this.formData) {
            return null;
        }
        return {
            attributes: this.deliveryMatrixItem.attributes,
            deliverableItemsListNumber: this.deliveryMatrixItem.deliverableItemsListNumber || null,
            deliveryDate: this.formData.deliveryDate || this.deliveryMatrixItem.deliveryDate || null,
            deliveryDestinationId: this.deliveryMatrixItem.deliveryDestination?.id || null,
            description: this.deliveryMatrixItem.description || '',
            dueDate: this.deliveryMatrixItem.dueDate || null,
            hardwareListId: this.deliveryMatrixItem.hardwareList?.id || null,
            helpNeededFromNasa: false,
            helpNeededFromNasaText: this.deliveryMatrixItem.helpNeededFromNasaText || null,
            isHardware: this.deliveryMatrixItem.isHardware || false,
            logisticsNeedDate: this.deliveryMatrixItem.logisticsNeedDate || null,
            modificationNumber: this.deliveryMatrixItem.modificationNumber || null,
            originalEstimatedCompletionDate: this.deliveryMatrixItem.originalEstimatedCompletionDate || null,
            requiredQuantity: this.deliveryMatrixItem.requiredQuantity || 1,
            requirementsDocumentNumber: this.deliveryMatrixItem.requirementsDocumentNumber || null,
            status: DeliveryMatrixItemStatus.COMPLETED,
            subType: this.deliveryMatrixItem.subType || null,
            taskOwnerContactId: this.deliveryMatrixItem.taskOwnerContact?.id || null,
            taskOwnerUpdateNeeded: this.deliveryMatrixItem.taskOwnerUpdateNeeded || false,
            workBreakdownStructureNumber: this.deliveryMatrixItem.workBreakdownStructureNumber || null
        };
    }
    get isFormValid() {
        return Boolean(this.formData.deliveryDate) && Boolean(this.attachmentFormData.description);
    }
    get latestStatusComment() {
        // TODO: Should be provided via the api https://gitlab.com/mri-technologies/cosmic/api/-/issues/342
        return null;
        // return (
        //   this.deliveryMatrixItem?.comments
        //     ?.filter((comment) => comment.subType === CommentType.STATUS)
        //     .sort(sortObjectBy('createdDateTime'))
        //     .pop() || null
        // );
    }
    get latestStatusCommentDate() {
        return this.$dateDisplay(this.latestStatusComment?.createdDateTime);
    }
    get latestStatusCommentText() {
        return this.latestStatusComment?.comment || null;
    }
    async fetchDeliveryMatrixItem(id) {
        try {
            if (!id) {
                return null;
            }
            this.isFetching = true;
            const resp = await this.$http.get(`/delivery-matrix-item/${this.id}`);
            if (resp.data) {
                this.deliveryMatrixItem = resp.data;
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
        this.isFetching = false;
    }
    async onClickOfComplete() {
        try {
            if (!this.id || !this.deliveryMatrixItemUpdateRequest || !this.deliveryMatrixItem) {
                return;
            }
            this.isSaving = true;
            // save a status comment if provided
            if (this.formData.comment) {
                const comment = await this.saveStatusComment(this.formData.comment);
                if (!comment) {
                    throw new Error('Failed to save status comment.');
                }
            }
            // save attachment if provided
            if (this.attachmentFormData.file) {
                await this.saveAttachment();
            }
            const resp = await this.$http.put(`/delivery-matrix-item/${this.id}`, this.deliveryMatrixItemUpdateRequest);
            // todo: we're making two calls here so we might want to refactor success
            // notifications and/or error messages to support the case where the
            // direct save works but the change request fail.
            if (resp.data) {
                this.$notification.add({
                    type: this.AlertType.SUCCESS,
                    text: 'Successfully completed Delivery Matrix item.'
                });
                // set ECD to delivery date
                const estimatedCompletionDate = this.formData.deliveryDate;
                const previousEstimatedCompletionDate = this.$dateDisplay(this.deliveryMatrixItem.estimatedCompletionDate);
                // if ECD changed, issue a change request
                if (previousEstimatedCompletionDate && previousEstimatedCompletionDate !== estimatedCompletionDate) {
                    const changeRequest = {
                        estimatedCompletionDate,
                        needType: this.deliveryMatrixItem.needType
                    };
                    await this.$http.put(`/delivery-matrix-item/${this.id}/change-request`, changeRequest);
                }
                this.emitCompleted();
                this.emitModalClose();
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
        this.isSaving = false;
    }
    onClickOfDropzone() {
        this.showAttachmentForm = true;
        this.$refs['attachment_form'].onClickOfDropzone();
    }
    onFileDropped(file) {
        this.showAttachmentForm = true;
        this.$refs['attachment_form'].onFileDropped(file);
    }
    async saveAttachment() {
        try {
            const { description, file, type } = this.attachmentFormData;
            if (!file || !this.currentUserActiveSupportContext) {
                return null;
            }
            if (!description) {
                throw new Error('Attachment description is required.');
            }
            if (!type) {
                throw new Error('Attachment type is required.');
            }
            // create the attachment entity
            const resp = await this.createAttachment(this.attachmentFormData);
            if (resp.data.id) {
                // relate the attachment to the delivery matrix item
                await this.httpPut(`/delivery-matrix-item/${this.id}/attachments`, [resp.data.id]);
            }
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
    async saveStatusComment(comment) {
        try {
            if (!comment) {
                return null;
            }
            const body = {
                subType: CommentType.STATUS,
                comment
            };
            const resp = await this.$http.post(`/comments`, body, {
                params: {
                    deliveryMatrixItemId: this.id
                }
            });
            return resp.data;
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({ err });
            }
            else {
                console.error(`Unexpected error: ${err}`);
            }
        }
    }
    emitCompleted() { }
    onChangeOfId(id) {
        if (!id || this.isFetching || this.deliveryMatrixItem) {
            return;
        }
        this.fetchDeliveryMatrixItem(id);
    }
};
__decorate([
    Prop({
        type: String,
        required: true
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], ModalDeliveryMatrixItemComplete.prototype, "id", void 0);
__decorate([
    Emit('completed'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ModalDeliveryMatrixItemComplete.prototype, "emitCompleted", null);
__decorate([
    Watch('id', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], ModalDeliveryMatrixItemComplete.prototype, "onChangeOfId", null);
ModalDeliveryMatrixItemComplete = __decorate([
    Component
], ModalDeliveryMatrixItemComplete);
export default ModalDeliveryMatrixItemComplete;
