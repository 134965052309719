var _a, _b;
import { __decorate, __metadata } from "tslib";
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { COSMIC_APPLICATION } from '~/application.config';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { CosmicUserSupportContext, EntityType, EntityTypeSingularDisplay, UserPermission } from '~/nasa_ui/types';
import { dateDisplay } from '~/nasa_ui/utils/dates/dateDisplay';
import { getAssetPath } from '~/nasa_ui/utils/helpers/assets';
import { isNullOrUndefined } from '~/nasa_ui/utils/helpers/isNullOrUndefined';
import { CosmicIcons } from '~/nasa_ui/utils/ui/CosmicIcons';
const LAUNCHPAD_ERROR_SLUG = 'Error: Network error: Unexpected token';
let TheNavbar = class TheNavbar extends Mixins(BaseCosmic) {
    alertLimit = 9;
    altLogoSrc = `${getAssetPath(COSMIC_APPLICATION)}/logistics_app_logo_retrowave.svg`;
    ariaSelected = false;
    dateDisplay = dateDisplay;
    hoverTimer = null;
    isHoveringOverHamburger = false;
    isHoveringOverNewTab = false;
    logoSrc = `${getAssetPath(COSMIC_APPLICATION)}/logistics_app_logo.svg`;
    navOpenState = null;
    swapLogos = false;
    userNeedsNewSession = false;
    userNeedsSessionInterval = null;
    createMenuItems = [
        // Assembly template
        {
            destinationUrl: `/hardware/${EntityType.ASSEMBLY_TEMPLATE}/create`,
            displayText: 'Assembly Template',
            icon: CosmicIcons[EntityType.ASSEMBLY_TEMPLATE],
            permissionToCheck: UserPermission.ASSEMBLY_TEMPLATE_ADMIN,
            supportContext: [CosmicUserSupportContext.ESOC],
            textColor: `var(--v-${EntityType.ASSEMBLY_TEMPLATE}-base)`
        },
        // Hardware list
        {
            destinationUrl: '/hardware/lists/create',
            displayText: EntityTypeSingularDisplay.get(EntityType.HARDWARE_LIST) || DEFAULT_DASH,
            icon: CosmicIcons[EntityType.HARDWARE_LIST],
            permissionToCheck: UserPermission.HARDWARE_LIST_TEMPLATE_ADMIN,
            supportContext: [CosmicUserSupportContext.ESOC],
            textColor: `var(--v-${EntityType.INVENTORY}-base)`
        },
        // Hardware list template
        {
            destinationUrl: '/hardware/list_templates/create',
            displayText: EntityTypeSingularDisplay.get(EntityType.HARDWARE_LIST_TEMPLATE) || DEFAULT_DASH,
            icon: CosmicIcons[EntityType.HARDWARE_LIST_TEMPLATE],
            permissionToCheck: UserPermission.HARDWARE_LIST_TEMPLATE_ADMIN,
            supportContext: [CosmicUserSupportContext.ESOC],
            textColor: `var(--v-${EntityType.INVENTORY}-base)`
        },
        // Organization
        {
            destinationUrl: `/${EntityType.ORGANIZATION}/create`,
            displayText: EntityTypeSingularDisplay.get(EntityType.ORGANIZATION) || DEFAULT_DASH,
            icon: CosmicIcons[EntityType.ORGANIZATION],
            permissionToCheck: UserPermission.ORGANIZATION_ADMIN,
            supportContext: [CosmicUserSupportContext.ESOC],
            textColor: `var(--v-${EntityType.ORGANIZATION}-base)`
        },
        // Create User
        {
            destinationUrl: `/${EntityType.USER}/create`,
            displayText: EntityTypeSingularDisplay.get(EntityType.USER) || DEFAULT_DASH,
            icon: CosmicIcons[EntityType.USER],
            permissionToCheck: UserPermission.USER_ADMIN,
            textColor: `var(--v-${EntityType.USER}-base)`,
            supportContext: [CosmicUserSupportContext.ESOC]
        },
        // User Group
        {
            destinationUrl: '/users/groups/create',
            displayText: EntityTypeSingularDisplay.get(EntityType.USER_GROUP) || '',
            icon: CosmicIcons[EntityType.USER_GROUP],
            permissionToCheck: UserPermission.USER_GROUPS_ADMIN,
            textColor: `var(--v-${EntityType.USER_GROUP}-base)`,
            supportContext: [CosmicUserSupportContext.ESOC]
        }
    ];
    outageAlertState;
    get computedLogoSrc() {
        return this.swapLogos ? this.altLogoSrc : this.logoSrc;
    }
    get navbarSettings() {
        return (this.currentUserActiveSupportContext &&
            this.currentUser?.attributes?.navbar?.[this.currentUserActiveSupportContext]);
    }
    get shouldShowNewBrowserWindowIcon() {
        return isNullOrUndefined(this.navbarSettings?.showNewBrowserWindowIcon)
            ? true
            : this.navbarSettings?.showNewBrowserWindowIcon;
    }
    get shouldShowUserSupport() {
        return isNullOrUndefined(this.navbarSettings?.showUserSupport) ? true : this.navbarSettings?.showUserSupport;
    }
    get usersManageLink() {
        return `/users/manage/${this.currentUser?.id}#settings`;
    }
    created() {
        this.navOpenState = localStorage.getItem('navOpenState') === 'true';
        this.$listen('closeTheSideNav', () => this.closeTheSideNav());
    }
    closeTheSideNav() {
        this.navOpenState = false;
    }
    handleLaunchpadSessionAuthorizeClick() {
        window.open('/api/oauth/authorize', '_blank');
    }
    onClickOfCosmicLogo(event) {
        // already at home
        if (!event.metaKey && !event.ctrlKey && this.$route.fullPath === '/') {
            event.preventDefault();
            return;
        }
        if (!event.metaKey && !event.ctrlKey) {
            event.preventDefault();
            this.$router.push('/');
        }
    }
    startTimer() {
        if (!this.hoverTimer) {
            this.hoverTimer = setTimeout(() => {
                this.swapLogos = true;
            }, 3000);
        }
    }
    stopTimer() {
        if (this.hoverTimer) {
            clearTimeout(this.hoverTimer);
            this.hoverTimer = null;
            this.swapLogos = false;
        }
    }
    toggleTheSideNavigationOpenState() {
        this.navOpenState = !this.navOpenState;
        this.ariaSelected = !this.ariaSelected;
    }
    onNavStateChange(val) {
        localStorage.setItem('navOpenState', val.toString());
        return val;
    }
    onUserNeedsNewSession(val) {
        if (val) {
            // start 2 sec polling to check for the user getting a fresh session again
            this.userNeedsSessionInterval = setInterval(() => { }, 5000);
        }
        else {
            if (this.userNeedsSessionInterval) {
                // when the flags is changed and is now false, we need to make sure we stop our polling
                clearInterval(this.userNeedsSessionInterval);
            }
            this.userNeedsSessionInterval = null;
        }
    }
    handleErrorSituation(err) {
        const errString = err.toString();
        // This is nominal in a user timeout situation
        if (this.userNeedsNewSession) {
            return;
        }
        // When the launchpad session times out it returns HTML here which throws the following error message
        if (errString.startsWith(LAUNCHPAD_ERROR_SLUG)) {
            this.userNeedsNewSession = true;
            this.$errorUtility({
                err,
                backupErrorText: 'Error: There was a Launchpad error.'
            });
            return;
        }
        // fallback if there another error
        this.$errorUtility({
            err,
            backupErrorText: 'Navbar failed to load all of its alert data.'
        });
    }
};
__decorate([
    Prop({
        type: String,
        required: false,
        default: null
    }),
    __metadata("design:type", Object)
], TheNavbar.prototype, "outageAlertState", void 0);
__decorate([
    Emit('nav-open-state'),
    Watch('navOpenState'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], TheNavbar.prototype, "onNavStateChange", null);
__decorate([
    Watch('userNeedsNewSession'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", void 0)
], TheNavbar.prototype, "onUserNeedsNewSession", null);
TheNavbar = __decorate([
    Component
], TheNavbar);
export default TheNavbar;
