var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('AppModal', {
    attrs: {
      "color": _vm.EntityType.DELIVERY_MATRIX_ITEM,
      "hide-title-section": "",
      "modal-width": "700px"
    },
    on: {
      "input": _vm.emitModalClose,
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.emitModalClose.apply(null, arguments);
      },
      "modal-close": _vm.emitModalClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs3": ""
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "DIL"
          }
        }, [_c('span', {
          staticClass: "monospace_font"
        }, [_vm._v(_vm._s(_vm.dil))])])], 1), _vm.deliveryMatrixItemDescription ? _c('v-flex', {
          attrs: {
            "xs9": ""
          }
        }, [_c('AppBlockquote', {
          attrs: {
            "title": "Description"
          }
        }, [_vm._v(_vm._s(_vm.deliveryMatrixItemDescription))])], 1) : _vm._e(), _c('v-flex', {
          attrs: {
            "xs12": "",
            "mb-2": ""
          }
        }, [_c('v-divider')], 1)], 1), _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [!_vm.latestStatusComment ? _c('Alert', {
          attrs: {
            "type": _vm.AlertType.INFO
          }
        }, [_vm._v("No existing status comment found.")]) : _c('AppBlockquote', {
          attrs: {
            "title": `Latest status comment (${_vm.latestStatusCommentDate})`
          }
        }, [_c('AppMarkdownDisplay', {
          attrs: {
            "markdown": _vm.latestStatusCommentText
          }
        })], 1)], 1), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppTextarea', {
          attrs: {
            "color": _vm.EntityType.DELIVERY_MATRIX_ITEM,
            "label": "Completion comment",
            "required": ""
          },
          model: {
            value: _vm.formData.comment,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "comment", $$v);
            },
            expression: "formData.comment"
          }
        })], 1)], 1), _c('v-layout', {
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('v-divider', {
          staticClass: "mb-2"
        })], 1), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppDate', {
          attrs: {
            "color": _vm.EntityType.DELIVERY_MATRIX_ITEM,
            "label": "Delivery",
            "required": ""
          },
          model: {
            value: _vm.formData.deliveryDate,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "deliveryDate", $$v);
            },
            expression: "formData.deliveryDate"
          }
        })], 1), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('v-divider', {
          staticClass: "mb-2"
        })], 1)], 1), _c('v-layout', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.showAttachmentForm,
            expression: "showAttachmentForm"
          }],
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppHeader', {
          attrs: {
            "color": _vm.EntityType.DELIVERY_MATRIX_ITEM
          }
        }, [_vm._v("Attachment")])], 1), _c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('AppFileAttachmentForm', {
          ref: "attachment_form",
          attrs: {
            "read-only": false
          },
          model: {
            value: _vm.attachmentFormData,
            callback: function ($$v) {
              _vm.attachmentFormData = $$v;
            },
            expression: "attachmentFormData"
          }
        })], 1)], 1), _c('v-layout', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.showAttachmentForm,
            expression: "!showAttachmentForm"
          }],
          attrs: {
            "row": "",
            "wrap": ""
          }
        }, [_c('v-flex', {
          attrs: {
            "xs12": ""
          }
        }, [_c('FileAttachmentDropzone', {
          on: {
            "drop": _vm.onFileDropped,
            "dropzone-clicked": _vm.onClickOfDropzone
          }
        })], 1)], 1)];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('ButtonSave', {
          attrs: {
            "disabled": !_vm.isFormValid,
            "icon": _vm.$icons.complete,
            "is-saving": _vm.isSaving
          },
          on: {
            "click": _vm.onClickOfComplete
          }
        }, [_vm._v(" Complete this item ")]), _c('ButtonCancel', {
          on: {
            "click": _vm.emitModalClose
          }
        })];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };