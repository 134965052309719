var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_c('v-layout', [_vm.changeLogsReportUrl ? _c('v-flex', {
    staticClass: "mb-2 text-xs-right",
    attrs: {
      "xs12": ""
    }
  }, [_vm.isItemInProcess ? _c('ButtonGeneric', {
    staticClass: "mr-2",
    attrs: {
      "color": "success",
      "icon": _vm.$icons.complete
    },
    on: {
      "click": function ($event) {
        _vm.showCompletionModal = true;
      }
    }
  }, [_vm._v(" Complete this item ")]) : _vm._e(), _vm.isItemInProcess ? _c('ButtonGeneric', {
    staticClass: "mr-2",
    attrs: {
      "color": "error",
      "icon": _vm.$icons.pipeline_canceled
    },
    on: {
      "click": _vm.onClickOfVoidItem
    }
  }, [_vm._v(" Void this item ")]) : _vm._e(), _vm.isItemVoid ? _c('ButtonGeneric', {
    staticClass: "mr-2",
    attrs: {
      "color": "success",
      "icon": _vm.$icons.refresh
    },
    on: {
      "click": _vm.onClickOfUnvoidItem
    }
  }, [_vm._v(" Mark as in process ")]) : _vm._e(), _c('ButtonLink', {
    staticClass: "primary",
    attrs: {
      "icon": _vm.$icons.report,
      "to": _vm.changeLogsReportUrl
    }
  }, [_vm._v(" View Change Logs ")])], 1) : _vm._e()], 1), _c('v-card', {
    staticClass: "pa-3 mb-2"
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_vm.childrenToTheSelectedHardwareList.length === 0 ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppCheckbox', {
    staticClass: "my-0",
    attrs: {
      "color": _vm.EntityType.ITEM_INSTANCE,
      "remove-margin-top": ""
    },
    model: {
      value: _vm.formData.isHardware,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "isHardware", $$v);
      },
      expression: "formData.isHardware"
    }
  }, [_vm._v(" Would you like to track Hardware for this item? ")])], 1) : _vm._e(), _vm.formData.isHardware ? _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppHardwareListAssemblies', {
    attrs: {
      "override-table-headers": _vm.overrideHardwareListAssemblyTableHeaders,
      "read-only": false,
      "hardware-list-id": _vm.deliveryMatrixItemHardwareListId,
      "show-additional-metadata": false
    }
  })], 1) : _c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('Alert', [_vm._v("Not tracking Hardware for this item.")])], 1)], 1)], 1), _c('v-card', {
    staticClass: "pa-2 mb-2"
  }, [_c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": ""
    }
  }, [_c('AppFormWrapper', {
    attrs: {
      "color": _vm.EntityType.DELIVERY_MATRIX_ITEM,
      "validate-on-load": "",
      "size": "0"
    }
  }, [_c('AppHeader', {
    staticClass: "mb-2"
  }, [_vm._v("Base Delivery item information")]), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": "",
      "mt-2": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": "",
      "md4": "",
      "lg3": "",
      "pr-2": ""
    }
  }, [_c('AppText', {
    attrs: {
      "color": _vm.EntityType.DELIVERY_MATRIX_ITEM,
      "label": "Deliverable items list (DIL) number",
      "required": ""
    },
    model: {
      value: _vm.formData.deliverableItemsListNumber,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "deliverableItemsListNumber", $$v);
      },
      expression: "formData.deliverableItemsListNumber"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "md6": ""
    }
  }, [_c('AppTextarea', {
    attrs: {
      "color": _vm.EntityType.DELIVERY_MATRIX_ITEM,
      "label": "Description",
      "required": ""
    },
    model: {
      value: _vm.formData.description,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "description", $$v);
      },
      expression: "formData.description"
    }
  })], 1)], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": "",
      "md4": "",
      "lg3": "",
      "pr-2": ""
    }
  }, [_c('AppSelectEnum', {
    attrs: {
      "chips": false,
      "color": _vm.EntityType.DELIVERY_MATRIX_ITEM,
      "display": _vm.DeliveryMatrixItemTypeDisplay,
      "enum": _vm.DeliveryMatrixItemType,
      "label": "Type",
      "required": ""
    },
    model: {
      value: _vm.formData.subType,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "subType", $$v);
      },
      expression: "formData.subType"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs5": "",
      "lg5": "",
      "pr-2": ""
    }
  }, [_c('AppContact', {
    attrs: {
      "chips": false,
      "label": "Task owner"
    },
    model: {
      value: _vm.formData.taskOwnerId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "taskOwnerId", $$v);
      },
      expression: "formData.taskOwnerId"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": "",
      "md4": "",
      "lg3": "",
      "pr-2": ""
    }
  }, [_c('AppSelect', {
    attrs: {
      "chips": false,
      "color": _vm.EntityType.DELIVERY_MATRIX_ITEM,
      "select-options": _vm.needTypeSelectOptions,
      "label": "Need",
      "required": ""
    },
    model: {
      value: _vm.formData.needType,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "needType", $$v);
      },
      expression: "formData.needType"
    }
  })], 1)], 1), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": "",
      "md4": "",
      "lg3": "",
      "pr-2": ""
    }
  }, [_c('AppText', {
    attrs: {
      "color": _vm.EntityType.DELIVERY_MATRIX_ITEM,
      "label": "WBS number"
    },
    model: {
      value: _vm.formData.workBreakdownStructureNumber,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "workBreakdownStructureNumber", $$v);
      },
      expression: "formData.workBreakdownStructureNumber"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs12": "",
      "sm6": "",
      "md4": "",
      "lg3": "",
      "pr-2": ""
    }
  }, [_c('AppSelectEnum', {
    attrs: {
      "chips": false,
      "color": _vm.EntityType.DELIVERY_MATRIX_ITEM,
      "display": _vm.DeliveryMatrixItemRequirementsDocumentDisplay,
      "enum": _vm.DeliveryMatrixItemRequirementsDocument,
      "label": "Requirements document number",
      "required": ""
    },
    model: {
      value: _vm.formData.requirementsDocumentNumber,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "requirementsDocumentNumber", $$v);
      },
      expression: "formData.requirementsDocumentNumber"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs3": "",
      "lg3": "",
      "pr-2": ""
    }
  }, [_c('AppText', {
    attrs: {
      "color": _vm.EntityType.DELIVERY_MATRIX_ITEM,
      "label": "Modification number",
      "required": ""
    },
    model: {
      value: _vm.formData.modificationNumber,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "modificationNumber", $$v);
      },
      expression: "formData.modificationNumber"
    }
  })], 1)], 1), _c('AppHeader', {
    staticClass: "mb-2"
  }, [_vm._v("Dates")]), _c('v-layout', {
    attrs: {
      "row": "",
      "wrap": ""
    }
  }, [_c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [_c('AppDate', {
    attrs: {
      "color": _vm.EntityType.DELIVERY_MATRIX_ITEM,
      "label": "Due"
    },
    model: {
      value: _vm.formData.dueDate,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "dueDate", $$v);
      },
      expression: "formData.dueDate"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [_c('AppDate', {
    attrs: {
      "color": _vm.EntityType.DELIVERY_MATRIX_ITEM,
      "label": "Delivery"
    },
    model: {
      value: _vm.formData.deliveryDate,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "deliveryDate", $$v);
      },
      expression: "formData.deliveryDate"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [_c('AppDate', {
    attrs: {
      "color": _vm.EntityType.DELIVERY_MATRIX_ITEM,
      "label": "Original est. completion"
    },
    model: {
      value: _vm.formData.originalEstimatedCompletionDate,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "originalEstimatedCompletionDate", $$v);
      },
      expression: "formData.originalEstimatedCompletionDate"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [_c('AppDate', {
    attrs: {
      "color": _vm.EntityType.DELIVERY_MATRIX_ITEM,
      "label": "Est. completion"
    },
    model: {
      value: _vm.formData.estimatedCompletionDate,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "estimatedCompletionDate", $$v);
      },
      expression: "formData.estimatedCompletionDate"
    }
  })], 1), _c('v-flex', {
    attrs: {
      "xs6": ""
    }
  }, [_c('AppDate', {
    attrs: {
      "color": _vm.EntityType.DELIVERY_MATRIX_ITEM,
      "label": "Logistics need"
    },
    model: {
      value: _vm.formData.logisticsNeedDate,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "logisticsNeedDate", $$v);
      },
      expression: "formData.logisticsNeedDate"
    }
  })], 1)], 1), _c('v-layout', [_c('v-flex', {
    attrs: {
      "mt-2": ""
    }
  }, [_c('ButtonSave', {
    attrs: {
      "is-saving": _vm.isSaving,
      "disabled": !_vm.isFormValid
    },
    on: {
      "click": _vm.onClickOfSave
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _vm.deliveryMatrixItem && _vm.deliveryMatrixItem.id && _vm.showCompletionModal ? _c('ModalDeliveryMatrixItemComplete', {
    attrs: {
      "id": _vm.deliveryMatrixItem.id
    },
    on: {
      "modal-close": function ($event) {
        _vm.showCompletionModal = false;
      },
      "completed": _vm.emitRefetchEntity
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };