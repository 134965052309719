var _a, _b;
import { __decorate, __metadata } from "tslib";
import isAfter from 'date-fns/isAfter';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import { EventType } from '~/db_types/swagger_types';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { ITEM_NUMBER_COL, SERIAL_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { EventRepoMixin } from '~/nasa_ui/DAL/event';
import { HardwareListRepo } from '~/nasa_ui/DAL/hardwareList';
import { AlertType, HeaderSortOptions } from '~/nasa_ui/types';
import { EventTypeDisplay } from '~/nasa_ui/types/enums/event';
let ModalCriticalInventoryTrackingEventEdit = class ModalCriticalInventoryTrackingEventEdit extends Mixins(BaseModal, BaseCosmic, EventRepoMixin, HardwareListRepo) {
    EventTypeDisplay = EventTypeDisplay;
    EventType = EventType;
    formData = {
        color: null,
        endDate: null,
        name: null,
        startDate: null,
        subType: EventType.GENERAL
    };
    hardwareTableHeaders = [
        {
            text: 'Type',
            value: '_type',
            width: '50px',
            align: 'center'
        },
        ITEM_NUMBER_COL,
        SERIAL_COL,
        {
            text: 'Location',
            value: '_location',
            sortable: true,
            noEscape: true,
            noWrap: true
        },
        {
            text: 'Birth',
            value: '_birthDate',
            align: 'right',
            headerSortType: HeaderSortOptions.DATE
        },
        {
            text: 'Expiry',
            value: '_usageLifeDate',
            align: 'right',
            headerSortType: HeaderSortOptions.DATE
        }
    ];
    event;
    hardwareListAssembliesWithEvents;
    get computedHardwareTableItems() {
        if (!this.event || !this.hardwareListAssembliesWithEvents) {
            return [];
        }
        // find the assemblies associated with the given event
        return this.hardwareListAssembliesWithEvents
            .filter((item) => item.events.some((ev) => ev.id === this.event.id))
            .map((item) => item.hardwareListAssembly);
    }
    get endDateErrorMessage() {
        return this.formData.startDate &&
            this.formData.endDate &&
            isAfter(new Date(this.formData.startDate), new Date(this.formData.endDate))
            ? 'Start can NOT be after End.'
            : null;
    }
    get isFormValid() {
        return Boolean(this.formData.startDate && this.formData.endDate && this.formData.name && this.formData.subType);
    }
    async onClickOfSave() {
        if (!this.isFormValid || !this.event?.id) {
            this.$notification.add({
                type: AlertType.ERROR,
                text: 'The form is not valid.'
            });
            return;
        }
        try {
            // Update the event
            // put color in `attributes.color`
            const event = await this.updateEvent(this.event.id, {
                endDate: this.formData.endDate,
                name: this.formData.name,
                startDate: this.formData.startDate,
                subType: this.formData.subType,
                attributes: {
                    color: this.formData.color
                }
            });
            if (!event.data.id) {
                this.$notification.add({
                    type: AlertType.ERROR,
                    text: 'Unable to update Event.'
                });
                this.$errorUtility({
                    err: new Error('Unable to update Event.')
                });
                return;
            }
            this.emitEntityEdited('Event updated.');
            this.emitModalClose();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    onChangeOfEvent(event) {
        if (!event) {
            return;
        }
        this.formData.name = event.name;
        this.formData.subType = event.subType;
        this.formData.startDate = event.startDate;
        this.formData.endDate = event.endDate;
        this.formData.color = event.attributes.color;
    }
};
__decorate([
    Prop({
        required: true,
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], ModalCriticalInventoryTrackingEventEdit.prototype, "event", void 0);
__decorate([
    Prop({
        required: true,
        type: Array
    }),
    __metadata("design:type", Array)
], ModalCriticalInventoryTrackingEventEdit.prototype, "hardwareListAssembliesWithEvents", void 0);
__decorate([
    Watch('event', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_b = typeof Maybe !== "undefined" && Maybe) === "function" ? _b : Object]),
    __metadata("design:returntype", void 0)
], ModalCriticalInventoryTrackingEventEdit.prototype, "onChangeOfEvent", null);
ModalCriticalInventoryTrackingEventEdit = __decorate([
    Component
], ModalCriticalInventoryTrackingEventEdit);
export default ModalCriticalInventoryTrackingEventEdit;
