var _a;
import { __decorate, __metadata } from "tslib";
import { addDays, addHours, addMinutes, isBefore, isEqual } from 'date-fns';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { AlertType, Maybe } from '~/nasa_ui/types';
import { popStoredAuthRedirect } from '~/nasa_ui/utils/authRedirect';
import CurrentUserMixin, { logout } from './nasa_ui/mixins/CurrentUserMixin';
import { dateTimeDisplay } from './nasa_ui/utils/dates/dateTimeDisplay';
import { markdownToHtml } from './nasa_ui/utils/helpers/markdownToHtml';
const OUTAGES_POLLING_RATE = 1000 * 60 * 5;
let LogisticsApp = class LogisticsApp extends Mixins(CurrentUserMixin) {
    AlertType = AlertType;
    navStateOpen = true;
    manuallyClosedOutageAlert = false;
    outages = [];
    outageTimerId = null;
    secondsUntilOutage = null;
    get isNextOutageWithinThreeHours() {
        if (!this.nextUpcomingOutage) {
            return false;
        }
        const now = new Date();
        const threeHoursFromNow = addHours(now, 3);
        const outageStart = new Date(this.nextUpcomingOutage.activeStartDateTime);
        return isBefore(outageStart, threeHoursFromNow);
    }
    get isNextOutageWithinThirtyMinutes() {
        if (!this.nextUpcomingOutage) {
            return false;
        }
        const now = new Date();
        const thirtyMinutesFromNow = addMinutes(now, 30);
        const outageStart = new Date(this.nextUpcomingOutage.activeStartDateTime);
        return isBefore(outageStart, thirtyMinutesFromNow);
    }
    get isOutageInProgress() {
        const secondsUntilOutage = this.secondsUntilOutage || 0;
        return Boolean(secondsUntilOutage <= 0);
    }
    get nextUpcomingOutage() {
        if (this.outages.length === 0) {
            return null;
        }
        const sortedOutages = [...this.outages];
        sortedOutages.sort((a, b) => {
            const startDateA = new Date(a.activeStartDateTime);
            const startDateB = new Date(b.activeStartDateTime);
            if (isEqual(startDateA, startDateB)) {
                return 0;
            }
            return isBefore(startDateA, startDateB) ? -1 : 1;
        });
        return sortedOutages[0];
    }
    get nextUpcomingOutageEnd() {
        return dateTimeDisplay(this.nextUpcomingOutage?.activeEndDateTime);
    }
    get nextUpcomingOutageStart() {
        return dateTimeDisplay(this.nextUpcomingOutage?.activeStartDateTime);
    }
    get nextUpcomingOutageWarningText() {
        return markdownToHtml(this.nextUpcomingOutage?.userFacingMessage);
    }
    get shouldShowCountdown() {
        return this.isNextOutageWithinThreeHours && !this.isOutageInProgress;
    }
    get shouldShowOutageBanner() {
        if (this.manuallyClosedOutageAlert) {
            return false;
        }
        if (this.isNextOutageWithinThreeHours) {
            return true;
        }
        if (this.nextUpcomingOutage) {
            return !localStorage.getItem(`hasUserSeenOutageError${this.nextUpcomingOutage.id}`);
        }
        else {
            return false;
        }
    }
    get outageAlertState() {
        if (this.isNextOutageWithinThirtyMinutes) {
            return AlertType.WARNING;
        }
        else if (this.isNextOutageWithinThreeHours) {
            return AlertType.ERROR;
        }
        else
            return null;
    }
    get timeUntilNextUpcomingOutageStarts() {
        const formatSecondsToHHMMSS = (seconds) => {
            const date = new Date(0);
            date.setSeconds(seconds); // specify value for SECONDS here
            return date.toISOString().substr(11, 8);
        };
        return formatSecondsToHHMMSS(this.secondsUntilOutage ?? 0);
    }
    onNavStateChange(val) {
        this.navStateOpen = val;
    }
    created() {
        this.getOutages();
        // Set Polling for Outages
        setInterval(() => {
            this.getOutages();
        }, OUTAGES_POLLING_RATE);
        const authRedirect = popStoredAuthRedirect();
        if (authRedirect) {
            this.$router.replace(authRedirect);
        }
    }
    closeAlert(e) {
        localStorage.setItem(`hasUserSeenOutageError${this.nextUpcomingOutage?.id}`, 'true');
        this.manuallyClosedOutageAlert = true;
    }
    logoutUser() {
        logout();
    }
    onChangeOfNextUpcomingOutage(outage) {
        if (!outage) {
            return;
        }
        this.manuallyClosedOutageAlert = false;
        if (this.isNextOutageWithinThreeHours && !this.outageTimerId) {
            this.startOutageCountdown();
        }
        else {
            this.calculateSecondsUntilOutage();
        }
    }
    calculateSecondsUntilOutage() {
        if (this.nextUpcomingOutage?.activeStartDateTime) {
            const outageStart = new Date(this.nextUpcomingOutage?.activeStartDateTime);
            const now = new Date();
            // starting value
            this.secondsUntilOutage = outageStart.getTime() / 1000 - now.getTime() / 1000;
        }
    }
    startOutageCountdown() {
        // function to recalute the number of seconds until the next outage begins
        this.calculateSecondsUntilOutage();
        // if timer is already running, clear it
        if (this.outageTimerId) {
            clearInterval(this.outageTimerId);
        }
        // update the value every second. saving the timer id to the instance so it
        // can be cleared when needed.
        this.outageTimerId = setInterval(() => {
            this.calculateSecondsUntilOutage();
            // when it gets to 0, stop the interval and log the user out
            if ((this.secondsUntilOutage ?? 0) <= 0 && this.outageTimerId) {
                clearInterval(this.outageTimerId);
                if (!this.currentUserIsAdmin) {
                    logout();
                }
            }
        }, 1000);
    }
    async getOutages() {
        try {
            const today = new Date();
            const threeDaysFromToday = addDays(today, 3);
            const resp = await this.getOutagesQuery(today.toISOString(), threeDaysFromToday.toISOString());
            if (resp) {
                // this is a little bit hacky but the purpose is to force watchers to
                // fire again--even if the outages haven't changed
                this.outages = [];
                this.outages = resp;
            }
        }
        catch (err) {
            this.$errorUtility({ err });
        }
    }
    getOutagesQuery(today, threeDaysFromToday) {
        return this.$http
            .get('/outages', {
            params: {
                includeDisabled: false,
                includeInactive: true,
                skip: 0,
                take: 10
            }
        })
            .then((resp) => {
            // find the outages that will be active within the next 3 days
            return resp?.data?.results?.filter((outage) => {
                if (outage.activeStartDateTime) {
                    return outage?.activeStartDateTime > today && outage?.activeStartDateTime < threeDaysFromToday;
                }
            });
        });
    }
};
__decorate([
    Watch('nextUpcomingOutage', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], LogisticsApp.prototype, "onChangeOfNextUpcomingOutage", null);
LogisticsApp = __decorate([
    Component
], LogisticsApp);
export default LogisticsApp;
