var _a;
import { __decorate, __metadata } from "tslib";
import { Component, Mixins, Prop } from 'vue-property-decorator';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import BaseVue from '~/nasa_ui/base/BaseVue';
import { Maybe } from '~/nasa_ui/types';
import { transformDeliveryMatrixItems } from '~/nasa_ui/utils/helpers/transformEntityToTableFormat';
let SummaryCardDeliveryMatrixItem = class SummaryCardDeliveryMatrixItem extends Mixins(BaseVue, BaseCosmic) {
    deliveryMatrixItem;
    get transformedDeliveryMatrixItem() {
        if (!this.deliveryMatrixItem) {
            return null;
        }
        return transformDeliveryMatrixItems([this.deliveryMatrixItem])[0];
    }
};
__decorate([
    Prop({
        required: true,
        type: Object
    }),
    __metadata("design:type", typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object)
], SummaryCardDeliveryMatrixItem.prototype, "deliveryMatrixItem", void 0);
SummaryCardDeliveryMatrixItem = __decorate([
    Component
], SummaryCardDeliveryMatrixItem);
export default SummaryCardDeliveryMatrixItem;
