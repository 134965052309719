var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.transformedDeliveryMatrixItem ? _c('v-card', {
    staticClass: "delivery_matrix_item_card my-1"
  }, [_c('v-toolbar', {
    attrs: {
      "color": _vm.EntityType.DELIVERY_MATRIX_ITEM
    }
  }, [_c('v-toolbar-title', {
    staticClass: "white--text"
  }, [_c('i', {
    staticClass: "mr-2",
    class: _vm.$icons[_vm.EntityType.DELIVERY_MATRIX_ITEM]
  }), _vm._v(" " + _vm._s(_vm.transformedDeliveryMatrixItem.description) + " ")]), _c('v-spacer'), _vm.deliveryMatrixItem ? _c('div', {
    staticClass: "d-flex"
  }, [_c('ButtonMiniActionManage', {
    attrs: {
      "to": `/${_vm.EntityType.DELIVERY_MATRIX_ITEM}/manage/${_vm.deliveryMatrixItem.id}`,
      "icon-size": "20",
      "tooltip": "Manage this Delivery Matrix item",
      "icon-color": "white--text"
    }
  })], 1) : _vm._e()], 1), _c('v-divider'), _c('v-list', {
    attrs: {
      "dense": "",
      "subheader": ""
    }
  }, [_c('v-subheader', [_c('i', {
    staticClass: "subheading",
    class: `${_vm.$icons.calendar} ${_vm.EntityType.DELIVERY_MATRIX_ITEM}--text`
  }), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v("Dates")])]), _c('v-list-tile', [_c('v-list-tile-content', [_c('span', [_vm._v("Due")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_c('strong', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedDeliveryMatrixItem._dueDate) + " ")])])], 1), _c('v-list-tile', [_c('v-list-tile-content', [_c('span', [_vm._v("Est. completion")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_c('strong', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedDeliveryMatrixItem._estimatedCompletionDate) + " ")])])], 1), _c('v-list-tile', [_c('v-list-tile-content', [_c('span', [_vm._v("Original est. completion")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_c('strong', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedDeliveryMatrixItem._originalEstimatedCompletionDate) + " ")])])], 1), _c('v-list-tile', [_c('v-list-tile-content', [_c('span', [_vm._v("Actual delivery")])]), _c('v-list-tile-content', {
    staticClass: "align-end data_item"
  }, [_c('strong', {
    staticClass: "monospace_font"
  }, [_vm._v(" " + _vm._s(_vm.transformedDeliveryMatrixItem._deliveryDate) + " ")])])], 1), _vm.transformedDeliveryMatrixItem.isHardware ? _c('v-divider') : _vm._e(), _vm.transformedDeliveryMatrixItem.isHardware ? _c('section', [_c('v-subheader', [_c('i', {
    staticClass: "subheading",
    class: `${_vm.$icons[_vm.EntityType.ITEM_INSTANCE]} ${_vm.EntityType.ITEM_INSTANCE}--text`
  }), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v("Hardware data")])]), _vm.transformedDeliveryMatrixItem.attributes.drawingNumber ? _c('v-list-tile', [_c('v-list-tile-content', [_c('span', [_vm._v("Drawing number")])]), _c('v-list-tile-content', {
    staticClass: "align-end"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.transformedDeliveryMatrixItem._drawingNumber) + " ")])])], 1) : _vm._e(), _vm.transformedDeliveryMatrixItem.attributes.asBuiltNumber ? _c('v-list-tile', [_c('v-list-tile-content', [_c('span', [_vm._v("AsBuilt")])]), _c('v-list-tile-content', {
    staticClass: "align-end"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.transformedDeliveryMatrixItem._asBuiltNumber) + " ")])])], 1) : _vm._e(), _vm.transformedDeliveryMatrixItem.attributes.hardwareClass ? _c('v-list-tile', [_c('v-list-tile-content', [_c('span', [_vm._v("Class")])]), _c('v-list-tile-content', {
    staticClass: "align-end"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.transformedDeliveryMatrixItem._contractEndingItemNumber) + " ")])])], 1) : _vm._e(), _vm.transformedDeliveryMatrixItem.attributes.serialNumber ? _c('v-list-tile', [_c('v-list-tile-content', [_c('span', [_vm._v("Serial")])]), _c('v-list-tile-content', {
    staticClass: "align-end"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.transformedDeliveryMatrixItem._serialNumber) + " ")])])], 1) : _vm._e()], 1) : _vm._e(), _c('v-divider'), _c('section', [_c('v-subheader', {
    staticClass: "status_subheading"
  }, [_c('i', {
    staticClass: "subheading",
    class: `${_vm.$icons[_vm.EntityType.DELIVERY_MATRIX_ITEM]} ${_vm.EntityType.DELIVERY_MATRIX_ITEM}--text`
  }), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v("Other")])]), _vm.transformedDeliveryMatrixItem.status ? _c('v-list-tile', [_c('v-list-tile-content', [_c('span', [_vm._v("Status")])]), _c('v-list-tile-content', {
    staticClass: "align-end"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.transformedDeliveryMatrixItem._status) + " ")])])], 1) : _vm._e(), _vm.transformedDeliveryMatrixItem.subType ? _c('v-list-tile', [_c('v-list-tile-content', [_c('span', [_vm._v("Type")])]), _c('v-list-tile-content', {
    staticClass: "align-end"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.transformedDeliveryMatrixItem._subType) + " ")])])], 1) : _vm._e(), _vm.transformedDeliveryMatrixItem.taskOwnerContact ? _c('v-list-tile', [_c('v-list-tile-content', [_c('span', [_vm._v("Task owner")])]), _c('v-list-tile-content', {
    staticClass: "align-end"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.transformedDeliveryMatrixItem._taskOwnerContact) + " ")])])], 1) : _vm._e(), _vm.transformedDeliveryMatrixItem.needType ? _c('v-list-tile', [_c('v-list-tile-content', [_c('span', [_vm._v("Need")])]), _c('v-list-tile-content', {
    staticClass: "align-end"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.transformedDeliveryMatrixItem._needType) + " ")])])], 1) : _vm._e()], 1)], 1)], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };