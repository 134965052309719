import { __decorate, __metadata } from "tslib";
import isAfter from 'date-fns/isAfter';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { HardwareListType } from '~/db_types';
import { EventType } from '~/db_types/swagger_types';
import BaseCosmic from '~/nasa_ui/base/BaseCosmic';
import BaseModal from '~/nasa_ui/base/BaseModal';
import { AS_BUILT_COL, BASE_DATE_COL, BASE_ICON_COL, BASE_TEXT_COL, DRAWING_NUMBER_COL, SERIAL_COL, SIDE_COL, SIZE_COL } from '~/nasa_ui/constants/genericColumnDefinitions';
import { EventRepoMixin } from '~/nasa_ui/DAL/event';
import { HardwareListRepo } from '~/nasa_ui/DAL/hardwareList';
import { AlertType } from '~/nasa_ui/types';
import { EventTypeDisplay } from '~/nasa_ui/types/enums/event';
import { composeTableHeader } from '~/nasa_ui/utils/helpers/composeTableHeader';
let ModalCriticalInventoryTrackingEventCreate = class ModalCriticalInventoryTrackingEventCreate extends Mixins(BaseModal, BaseCosmic, EventRepoMixin, HardwareListRepo) {
    EventTypeDisplay = EventTypeDisplay;
    EventType = EventType;
    formData = {
        color: null,
        endDate: null,
        name: null,
        selectedHardwareListAssemblies: [],
        startDate: null,
        subType: EventType.GENERAL
    };
    hardwareTableHeaders = [
        composeTableHeader(BASE_ICON_COL, {
            text: 'Type',
            value: '_type',
            width: '50px'
        }),
        composeTableHeader(DRAWING_NUMBER_COL, {
            value: '_computedDrawingNumber'
        }),
        composeTableHeader(AS_BUILT_COL, {
            value: '_computedAsBuiltNumber'
        }),
        composeTableHeader(SIDE_COL, {
            value: '_computedSide'
        }),
        composeTableHeader(SIZE_COL, {
            value: '_computedSize'
        }),
        composeTableHeader(SERIAL_COL, {
            value: '_computedSerialNumber'
        }),
        composeTableHeader(BASE_TEXT_COL, {
            text: 'Location',
            value: '_computedLocation',
            noWrap: true,
            noEscape: true
        }),
        composeTableHeader(BASE_DATE_COL, {
            text: 'Birth',
            value: '_computedBirthDate'
        })
    ];
    // These are expected to be transformed already via `transformHardwareListAssemblies`
    hardwareListAssemblies;
    get computedHardwareTableItems() {
        return this.hardwareListAssemblies;
    }
    get endDateErrorMessage() {
        return this.formData.startDate &&
            this.formData.endDate &&
            isAfter(new Date(this.formData.startDate), new Date(this.formData.endDate))
            ? 'Start can NOT be after End.'
            : null;
    }
    get isFormValid() {
        return Boolean(this.formData.startDate &&
            this.formData.endDate &&
            this.formData.name &&
            this.formData.subType &&
            this.formData.selectedHardwareListAssemblies.length);
    }
    async onClickOfCreate() {
        if (!this.isFormValid) {
            this.$notification.add({
                type: AlertType.ERROR,
                text: 'The form is not valid.'
            });
            return;
        }
        try {
            // Create Event
            // put color in `attributes.color`
            const event = await this.createEvent({
                endDate: this.formData.endDate,
                name: this.formData.name,
                startDate: this.formData.startDate,
                subType: this.formData.subType,
                attributes: {
                    color: this.formData.color
                }
            });
            if (!event.data.id) {
                this.$notification.add({
                    type: AlertType.ERROR,
                    text: 'Unable to create Event.'
                });
                this.$errorUtility({
                    err: new Error('Unable to create Event.')
                });
                return;
            }
            // Create HardwareList specific for this grouping
            // Attach HL to Event
            const hardwareListForThisEvent = await this.createHardwareList({
                input: {
                    hardwareList: {
                        number: this.createHardwareListNumber(event.data.id),
                        subType: HardwareListType.HARDWARE,
                        eventId: event.data.id,
                        name: this.createHardwareListName(event.data.id)
                    }
                }
            });
            const hardwareListId = hardwareListForThisEvent?.createHardwareList?.hardwareList?.id;
            if (!hardwareListId) {
                this.$notification.add({
                    type: AlertType.ERROR,
                    text: 'Unable to create list for this Event.'
                });
                this.$errorUtility({
                    err: new Error('Unable to create list for this Event.')
                });
                return;
            }
            // Create a HardwareListAssembly for each Hardware selected
            const allPromises = this.formData.selectedHardwareListAssemblies.map((hla, index) => this.createHardwareListAssembly({
                input: {
                    hardwareListAssembly: {
                        externalAsBuiltNumber: hla.externalAsBuiltNumber,
                        externalBirthDate: hla.externalBirthDate,
                        externalCalibrationDate: hla.externalCalibrationDate,
                        externalCalibrationNumber: hla.externalCalibrationNumber,
                        externalClass: hla.externalClass,
                        externalDescription: hla.externalDescription,
                        externalDrawingNumber: hla.externalDrawingNumber,
                        externalIsTool: hla.externalIsTool,
                        externalLocation: hla.externalLocation,
                        externalLotNumber: hla.externalLotNumber,
                        externalSerialNumber: hla.externalSerialNumber,
                        externalShelfLifeDate: hla.externalShelfLifeDate,
                        externalUsageLifeDate: hla.externalUsageLifeDate,
                        inventoryId: hla.inventoryId,
                        isExternal: hla.isExternal,
                        quantity: hla.quantity,
                        sequence: index + 10,
                        hardwareListId: hardwareListForThisEvent?.createHardwareList?.hardwareList?.id
                    }
                }
            }));
            const hardwareListAssemblies = await Promise.all(allPromises);
            this.emitEntityCreated('Event created and Hardware attached.');
            this.emitModalClose();
        }
        catch (err) {
            if (err instanceof Error) {
                this.$errorUtility({
                    err
                });
            }
            else {
                console.log(`Unexpected error: ${err}`);
            }
        }
    }
    createHardwareListNumber(eventId) {
        const year = new Date().getFullYear().toString().substring(2); // YY format
        return `CIT-${year}-${eventId}`;
    }
    createHardwareListName(eventId) {
        return `List for ${eventId}`;
    }
};
__decorate([
    Prop({
        required: true,
        type: Array
    }),
    __metadata("design:type", Array)
], ModalCriticalInventoryTrackingEventCreate.prototype, "hardwareListAssemblies", void 0);
ModalCriticalInventoryTrackingEventCreate = __decorate([
    Component
], ModalCriticalInventoryTrackingEventCreate);
export default ModalCriticalInventoryTrackingEventCreate;
